import React, { useRef } from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'
import PeopleGrid from '../components/people-grid'
import Container from '../components/container'
import Careers from '../components/careers'
import Button from '../components/button'
import SEO from '../components/seo'

import Layout from '../containers/layout'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

export const query = graphql`
  query AboutPageQuery {
    page: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      image {
        asset {
          _id
        }
      }
      _rawBody
      people {
        _key
        image {
          asset {
            _id
          }
        }
        name
        title
        _rawBio
      }
      careers
      careersCTA
      careersDest
    }
    careers: allSanityCareer(limit: 4, sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          id
          publishedAt
          title
          excerpt
          _rawBody
          slug {
            current
          }
          apply
        }
      }
    }
  }
`

const ParagraphClass = 'inline-block relative w-full text-sm  text-white'
const HeadingClass = 'inline-block relative w-full text-xl md:text-3xl my-8'

const AboutPage = props => {
  const { data, errors } = props

  // const careersRef = useRef(null);
  // const ScrollToCareers = () => {
  //   window.scrollTo({ top: careersRef.current.offsetTop, behavior: "smooth"})
  // }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  const personNodes = data && data.page && data.page.people
  const careerNodes =
    data && data.careers && mapEdgesToNodes(data.careers).filter(filterOutDocsWithoutSlugs)

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }


  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
        <div className="inline-block hill-background relative w-full px-10 lg:px-20 py-20 lg:py-32 md:pb-32 text-left md:text-center">
          <div className="mx-0 lg:mx-56">
            <PageHeader
              title={page.title || `About us`}
              image={page.image ? page.image : undefined}
              body={page._rawBody ? page._rawBody : undefined}
              className="hill-background"
            />
            {/* <Button 
              primary
              outlined
              text='Join Our Team'
              onClick={ScrollToCareers()}
            />
            <button onClick={ScrollToCareers()}>
              Join Our Team
            </button> */}

          </div>
          <div className="inline-block relative mt-10 w-full">
            {personNodes && personNodes.length > 0 ? (
              <div className="inline-block relative w-full lg:px-10">
                <PeopleGrid items={personNodes} title="Our team" />
              </div>
            ) : null}
            <div className="inline-block relative w-full lg:px-20">
              <h4 className='inline-block relative w-full text-xl md:text-5xl font-bold my-8'>Careers</h4>
              <p className={`${ParagraphClass} mb-8 text-white`}>{page.careersCTA}</p>
              {page.careers ? (
                <div className="inline-block relative w-full" 
                  // ref={careersRef}
                >
                  <Careers nodes={careerNodes} />
                  {/* <Button outlined primary width={'w-auto'} padding={'px-20'} text="See All Careers" to="/careers" /> */}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default AboutPage
