import React from 'react'

import BlockText from './block-text'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const ParagraphClass = 'inline-block relative w-full text-xs text-gray-300 my-4'
const HeadingClass = 'inline-block relative  text-center w-full text-xl text-3xl my-8'

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 2
}

function ProfileCard({ image, title, name, _rawBio }) {
  return (
    <div className="inline-block relative rounded-sm overflow-hidden  text-left">
      <>
        {image && image.asset && (
          <img
            className="inline-block relative w-full mb-4"
            src={imageUrlFor(buildImageObj(image))
              .width(500)
              .height(500).crop("top")
              .fit('crop')
              .url()}
          />
        )}
      </>
      <strong className="inline-block  relateive w-1/2 lg:w-full text-lg text-white font-medium">
        {name}
      </strong>
      {title && <p className="inline-block relative w-full text-sm text-secondary">{title}</p>}
      {_rawBio && (
        <div className={ParagraphClass}>
          <BlockText blocks={_rawBio} />
        </div>
      )}
    </div>
  )
}

function PeopleGrid({ items, title }) {
  return (
    <div>
      {/* {<h3 className={HeadingClass}>{title}</h3>} */}
      <div className="grid w-full gap-2  grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 lg:gap-6">
        {items.map(item => (
          <ProfileCard {...item} key={item._key} />
        ))}
      </div>
    </div>
  )
}

export default PeopleGrid
